/* import poppins from google-fonts/poppins.css; */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html,
body,
#root {
  height: 100% !important;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.price-table td {
  line-height: 4.5;
}
